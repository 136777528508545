import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type LinkIconProps = {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
};

export default chakra(function ListIcon({ className, width, height, fill }: LinkIconProps) {
  return (
    <Icon
      className={className}
      viewBox='0 0 24 24'
      width={width || '24'}
      height={height || '24'}
      fill={fill || '#CED4DA'}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 6H3V8H5V6ZM5 11H3V13H5V11ZM3 16H5V18H3V16ZM21 6V8H7V6H21ZM7 11H21V13H7V11ZM21 16H7V18H21V16Z'
      />
    </Icon>
  );
});
