import React, { ReactNode, useContext, useEffect, useState } from "react";
import FooterContext from "../context/FooterContext";
import HeaderContext from "../context/HeaderContent";
import { useBreakpointValue } from "@chakra-ui/react";

type SqueezeToFitProps = {
  navHeaderRef?: React.MutableRefObject<HTMLDivElement>;
  className?: string;
  children: ReactNode;
};

const SqueezeToFit = React.forwardRef<HTMLDivElement, SqueezeToFitProps>(function SqueezeToFit(
  { children, navHeaderRef, ...props }: SqueezeToFitProps,
  ref
) {
  const isMobile = useBreakpointValue({ base: true, lg: false }, "lg");
  const PADDING = isMobile ? 35 : 75;
  const getHeaderRef = useContext(HeaderContext);
  const getFooterRef = useContext(FooterContext);

  const [height, setHeight] = useState("100vh");
  const [top, setTop] = useState("55px");
  const [maxHeight, setMaxHeight] = useState("100vh");

  useEffect(() => {

    const onScroll = () => {
      if (!getHeaderRef) return;
      const headerRef = getHeaderRef();
      const footerRef = getFooterRef();
      if (!headerRef || !navHeaderRef) return;
      const headerTop = headerRef.offsetHeight;
      const footerTop = footerRef.getBoundingClientRect().top;


      const footerVisible = false;
      const bottom = window.innerHeight - (window.innerHeight - footerTop);
      const height = bottom - headerTop;
      if (footerVisible) {
        setHeight(Math.round(height - PADDING) + "px");
        setTop(top);
        setMaxHeight(Math.round(height - PADDING) + "px");
      } else {
        setHeight(Math.round(window.innerHeight - headerTop - PADDING) + "px");
        setTop(top);
        setMaxHeight(Math.round(window.innerHeight - headerTop - PADDING) + "px");
      }
    };

    onScroll();

    document.addEventListener("scroll", () => onScroll());

    return () => document.removeEventListener("scroll", () => onScroll());
  }, [isMobile, getHeaderRef, getFooterRef, navHeaderRef, PADDING, top]);

  return (
    <div ref={ref} style={{ height, maxHeight, top }} {...props}>
      {children}
    </div>
  );
});

export default SqueezeToFit;
