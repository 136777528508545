import React from 'react';

import Container from '../../components/Container';
import Link from '../../components/Link';
import { getPathInfo } from '../../services/URL';
import { getPlatformSpellingForTitle } from '../../../processing/text';
import { getFormattedNavLink } from '../../services/DocumentationService';
import './FAQIndexTemplate.scss';
import './FAQIndexTemplate.mobile.scss';
import SideNav from '../../components/SideNav';
import { Box, Flex } from '@chakra-ui/react';

export default class FAQIndexTemplate extends React.Component {


  getGroups = (data, platform) => {
    const groups = [];
    let currentGroup = { links: [] };
    let firstHeader = true;

    data.forEach(d => {
      if (d.header) {
        if (firstHeader) {
          firstHeader = false;
        } else {
          groups.push(currentGroup);
        }

        currentGroup = {
          header: d.header,
          links: []
        };
      } else {
        if ((d.link === 'ignore' && !d[platform]) || d[platform] === 'ignore') {
          return;
        }
        currentGroup.links.push(d);
      }
    });

    groups.push(currentGroup);

    return groups;
  }

  render() {

    const { pageContext, location } = this.props;
    const { platform } = getPathInfo(location.pathname);
    const groups = this.getGroups(pageContext.links, platform);

    return (
      <Box className='FAQIndexTemplate' >
        <SideNav sideNavs={[]} pathname={location.pathname} platform={platform} />
        <Flex flexDir={'row'} justifyContent='center' w='full'>
          <Box className='content-wrapper' marginTop={{ base: '13px', lg: 'auto' }}>
            <Container style={{ marginTop: '85px' }}>
              <h1>Apryse SDK for {getPlatformSpellingForTitle(platform)} FAQ</h1>

              <div className='cards'>
                {
                  groups.map(group => {

                    return (
                      <div className='card' key={group.header}>
                        <h2>{group.header}</h2>

                        {
                          group.links.map(link => {

                            const { link: l } = getFormattedNavLink(link[platform] || link.link, platform);

                            if (link.subheader) {
                              return (
                                <div className='sub' key={link.subheader}>
                                  <h3>{link.subheader}</h3>

                                  {
                                    link.links.map(l => {
                                      const { link: url } = getFormattedNavLink(l[platform] || l.link, platform)
                                      return <Link to={url} key={url}><p>{l.title}</p></Link>
                                    })
                                  }
                                </div>

                              )

                            }

                            return <Link to={l} key={l}><p>{link.title}</p></Link>
                          })
                        }
                      </div>
                    );
                  })}
              </div>
            </Container>
          </Box>
        </Flex>
      </Box>
    );
  }

}