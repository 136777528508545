import React, { PureComponent } from 'react';

import './Container.scss';

export default class Container extends PureComponent {
  getHeight = () => {
    return this.$container.offsetHeight;
  }

  render() {
    const { style = {}, children, className = '' } = this.props;

    return (
      <div className={`Container ${className}`} style={style} ref={(ref) => { this.$container = ref }}>
          {children}
      </div>
    );
  }
}