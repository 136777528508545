import { Box, Button, chakra, Flex, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ListIcon from '../../icons/ListIcon';
import HeaderState from '../../context/HeaderState';

const capitalizeString = (str: string) => {
  if (!str) return '';
  if (str === 'ios') return 'iOS';
  if (str === 'uwp') return 'UWP';
  if (str === 'faq') return 'FAQ';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default chakra(function MobileTopNav({
  platform,
  section,
  activeNavItem,
  onOpen,
}: {
  platform: string;
  section: string;
  activeNavItem: any;
  onOpen: () => void;
}) {
  const headerState = useContext(HeaderState);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bannerIsShown = useMemo(() => scrollPosition < 36, [scrollPosition]);
  const transformHeaderHidden = useMemo(() => (bannerIsShown ? -100 : -64), [bannerIsShown]);
  const transformHeaderShown = useMemo(() => {
    if (bannerIsShown) {
      return Math.max(0 - scrollPosition, 0);
    }
    return 0;
  }, [bannerIsShown, scrollPosition]);
  const slash = ' / ';
  const transition = bannerIsShown ? 'transform 0s' : 'transform 0.2s';
  return (
    <Flex
      display={{ base: 'flex', lg: 'none' }}
      w='full'
      h='64px'
      bg='black'
      px='20px'
      py='26px'
      alignItems={'center'}
      transform={
        headerState === 'hidden'
          ? `translate(0px, ${transformHeaderHidden}px)`
          : `translate(0px, ${transformHeaderShown}px)`
      }
      transition={transition}
      position='fixed'
      zIndex={99}
      mt='64px'
    >
      {onOpen !== null && <Button onClick={onOpen} bgColor='none' p={0}>
        <ListIcon w='24px' h='24px' />
      </Button>}
      <Text px='12px' color={'#CED4DA'}>
        {capitalizeString(platform) +
          slash +
          (capitalizeString(section) || 'Guides') +
          (activeNavItem?.title ? slash + activeNavItem?.title : '')}
      </Text>
    </Flex>
  );
});
