import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import ClassNames from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import { IOS, UWP } from '../../../config/platforms';
import SqueezeToFit from '../../components/SqueezeToFit';
import ChevronIcon from '../../icons/ChevronIcon';
import {
  getFormattedNavLink,
  setNavItems,
} from '../../services/DocumentationService';
import { getPathInfo, isDocPage, urlsMatch } from '../../services/URL';
import Link from '../Link';
import './SideNav.mobile.scss';
import './SideNav.scss';

let activeMap = {};

export default class SideNav extends React.Component {

  constructor(props) {
    super(props);
    this.headerLinks = {};

    this.state = {
      mobileHidden: true,
    };

    this.$wrapperRef = React.createRef();
    this.$scrollRef = React.createRef();
    this.navHeaderRef = React.createRef();
  }

  componentDidMount() {
    const ele = document.getElementById('sidenav-active');
    const { platform } = getPathInfo(this.props.pathname);
    this.$scrollRef.current.scrollTop = sessionStorage[`@pdftron_side_${platform}`]

    if (ele) {
      const top = ele.offsetTop;

      if (this.$scrollRef.current && top <= this.$scrollRef.current.scrollTop) {
        this.$scrollRef.current.scrollTop = top;
      }

      if (this.$scrollRef.current && top >= this.$scrollRef.current.scrollTop + window.innerHeight) {
        this.$scrollRef.current.scrollTop = top;
      }
    }
  }

  onScroll = (e) => {
    const { platform } = getPathInfo(this.props.pathname);
    if (this.$scrollRef && this.$scrollRef.current) {
      const scrollTop = this.$scrollRef.current.scrollTop;
      sessionStorage[`@pdftron_side_${platform}`] = scrollTop;
    }

    e.stopPropagation();
  }

  getRef = () => {
    return this.$r;
  }

  toggleActive = (item) => {
    if (activeMap[item]) activeMap[item] = false;
    else activeMap[item] = true;
  }

  setActive = (item) => {
    if (item && activeMap[item] === undefined) {
      activeMap[item] = true;
    }

  }

  getActiveMap = () => {
    return Object.keys(activeMap).filter(key => {
      return activeMap[key] === true;
    })
  }

  getSideNavContents(items, activeNavItem, arr = [], depth = 1) {
    if (typeof window === 'undefined') return arr;

    const { platform } = this.props;
    const depthClass = `d${depth}`;

    const { lastHeader, lastSubheader } = activeNavItem || {};

    this.setActive(lastHeader);
    this.setActive(lastSubheader);

    const activeHeaders = this.getActiveMap();

    const ignoreHeader = {};

    items.forEach(sideNav => {

      const { header, title, subheader, id } = sideNav;

      // if its a header item
      if (header) {
        if (sideNav[platform] === 'ignore') {
          ignoreHeader[header] = true;
          return;
        }

        const { link, rightPanel } = sideNav;

        const active = activeHeaders.includes(header) || urlsMatch(link, window.location.pathname);
        const headerClassName = ClassNames({
          header: true,
          [depthClass]: true,
          active
        });

        const onClick = () => {
          if (!rightPanel && !link) {
            this.toggleActive(header);
          }

          if (rightPanel) {
            navigate('https://dev.apryse.com/')
          }

          this.forceUpdate();
        }

        // if its a header link
        if (link || rightPanel) {
          arr.push(
            <Link
              onClick={onClick}
              style={{ display: 'block', margin: 0, padding: '7px 5px' }}
              to={link}
              active={active}
              key={id}
              forceHighlight
              className={headerClassName}
              id={active ? 'sidenav-active' : ''}
            >
              <p>{header}</p>
            </Link>
          )
          return;
        }

        arr.push(
          <div className={headerClassName} onClick={onClick} key={id}>
            <ChevronIcon
              fill='apryseIndigo'
              transform={`translateY(5px) rotate(${active ? 0 : -90}deg) `}
              marginRight='4px'
              width='12px'
              transition='all 0.05s ease'
            />
            <Heading size='h6'>{header}</Heading>
          </div>
        )

        return;
      }

      // if its a link item
      if (title) {
        let {
          link,
          rightPanel,
          id,
          lastHeader: itemLastHeader,
        } = sideNav;

        // if link, platform, or header is ignore
        if ((link === 'ignore' && !sideNav[platform]) || sideNav[platform] === 'ignore' || !!ignoreHeader[itemLastHeader]) {
          return;
        }

        if (sideNav[platform]) {
          link = sideNav[platform];
        }

        let onClick = () => {

          if (rightPanel) {
            navigate('https://dev.apryse.com/')
          }
          if (this.props.onMobileSideNavClose) {
            this.props.onMobileSideNavClose();
          }
          this.toggleMobileMenu();
        }


        // if the top level header isnt active then return no matter what
        if (!activeHeaders.includes(itemLastHeader)) return;
        let active = sideNav === activeNavItem;

        ({ link } = getFormattedNavLink(link, platform, window.location.pathname));

        const linkClassName = ClassNames({
          'sidenav-link': true,
          [depthClass]: true,
          active
        })

        arr.push(
          <span key={id || `${title}-${depth}-${link}`}>
            <Link
              onClick={onClick}
              style={{ display: 'block', margin: 0, padding: '7px 5px' }}
              to={link}
              active={active}
              className={linkClassName}
              forceHighlight
              id={active ? 'sidenav-active' : ''}
            >
              {title}
            </Link>
          </span>
        )
        return;
      }

      // if its a subheader
      if (subheader) {
        let {
          links,
          id,
          lastHeader: itemLastHeader,
        } = sideNav;

        // if platform, or header is ignore
        if (sideNav[platform] === 'ignore' || !!ignoreHeader[itemLastHeader]) {
          ignoreHeader[subheader] = true;
          return;
        }

        if (!activeHeaders.includes(itemLastHeader)) return;

        const active = activeHeaders.includes(subheader);

        const subheaderClassName = ClassNames({
          header: true,
          subheader: true,
          active
        })

        const onClick = () => {
          this.toggleActive(subheader);
          this.forceUpdate();
        }

        arr.push(
          <div className={subheaderClassName} onClick={onClick} key={id}>
            <ChevronIcon
              fill='apryseIndigo'
              transform={`translateY(5px) rotate(${active ? 0 : -90}deg) `}
              marginRight='4px'
              width='12px'
              transition='all 0.05s ease'
            />
            <Text>{subheader}</Text>
          </div>
        )

        links = links.map(link => {
          link.lastHeader = subheader;
          return link;
        })

        arr = this.getSideNavContents(links, activeNavItem, arr, depth + 1).content;
        return;
      }
    })

    return { content: arr, activeNavItem }
  }

  toggleMobileMenu = () => {
    if (window.innerWidth < 900) {
      const nextState = !this.state.mobileHidden;
      window.setBodyScroll(!nextState);
      window.modalOpen = !nextState;

      window.mobileMenuOpen = !nextState;

      this.setState({
        mobileHidden: nextState
      })
    } else {
      window.modalOpen = false;

      window.mobileMenuOpen = false;
    }
  }

  upperCase = (string) => {
    if (!string) return '';
    if (string === IOS) return 'iOS';
    if (string === UWP) return 'UWP';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { sideNavs, pathname, activeNavItemData } = this.props;
    // normally this logic should be in SideNav.js, but the FooterNav component needs this data too
    let { activeNavItem, nextNavItem, previousNavItem } = activeNavItemData;
    setNavItems({ nextNavItem, previousNavItem });


    const { content } = this.getSideNavContents(sideNavs, activeNavItem, [], 1);
    if (!content || content.length === 0) { 
      this.props.setShowSideNav(false);
    }

    const baseClassName = `SideNav ${sideNavs.length ? 'visible' : 'hidden'} ${isDocPage(pathname) ? 'doc-page' : 'faq-page'}`
    return (
      <Flex className='sidenav-wrapper' ref={this.$wrapperRef} mt='12px' flexDir={'column'}>
        <SqueezeToFit
          id='side-nav'
          navHeaderRef={this.navHeaderRef}
          className={`${baseClassName}`}
          ref={this.$scrollRef}
          onScroll={this.onScroll}>
          <Box
            ml='12px'
            w={{ base: '96%', lg: '260px' }}
            maxW={{ base: '96%', lg: '260px' }}
            onScroll={this.onScroll}
            className='sidenav-items-wrapper'
          >
            {content}
          </Box>
        </SqueezeToFit>
      </Flex>
    );
  }
}
