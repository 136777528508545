import {
  chakra,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import MobileDocumentationHeader from '../../components/Header/MobileDocumentationHeader';
import SideNav from './SideNav';
import './SideNav.mobile.scss';
import './SideNav.scss';
import {
  formatNavItems,
  getActiveNavItemData,
} from '../../services/DocumentationService';
import { getPathInfo } from '../../services/URL';

export default chakra(function SideNavWrapper(props) {
  const {
    isOpen: isMobileSideNavOpen,
    onOpen: onMobileSideNavOpen,
    onClose: onMobileSideNavClose,
  } = useDisclosure();

  const data = useStaticQuery(graphql`
    query {
      allSideNavYaml(filter: { slug: { regex: "/navs/" } }) {
        edges {
          node {
            id
            slug
            group
            header
            chunk
            title
            link
            android
            ios
            web
            uwp
            xamarin
            linux
            windows
            mac
            core
            cli
            dotnet
            dotnetcore
            nodejs
            java
            cpp
            php
            python
            ruby
            go
            autoexpand
            subheader
            rightPanel
            links {
              title
              link
              android
              ios
              web
              uwp
              xamarin
              linux
              windows
              mac
              core
              cli
              dotnet
              dotnetcore
              nodejs
              java
              cpp
              php
              python
              ruby
              go
              chunk
            }
          }
        }
      }
    }
  `);
  
  const [showSideNav, setShowSideNav] = React.useState(true);
  const chunks = useMemo(() => data.allSideNavYaml.edges.map((n) => n.node), [data.allSideNavYaml.edges]);
  const sideNavs = props.sideNavs;
  const formattedSideNavs = useMemo(() => sideNavs ? formatNavItems(sideNavs, chunks) : [], [sideNavs, chunks]);
  const { platform, section } = useMemo(() => getPathInfo(props.pathname), [props.pathname]);
  const activeNavItemData = useMemo(() => getActiveNavItemData(formattedSideNavs, platform, props.pathname), [formattedSideNavs, platform, props.pathname]);
  const [screenWidth, setScreenWidth] = useState(0);

  // Add event listener to update screenWidth on resize
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    if(screenWidth > 1200) {
      onMobileSideNavClose()
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Box>
      <Box display={{ base: 'block', lg: 'none' }} zIndex={100}>
        {showSideNav && <MobileDocumentationHeader
          platform={platform}
          section={section}
          onOpen={onMobileSideNavOpen}
          activeNavItem={activeNavItemData.activeNavItem}
        />}
        {screenWidth < 1200 && <Drawer
          isOpen={isMobileSideNavOpen}
          placement='left'
          onClose={onMobileSideNavClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              top='6px'
              right='8px'
              color={'apryseIndigo'}
              size={'md'}
              _focus={{ outline: 'none' }}
              p={0}
            />
            <DrawerBody mt='20px' p={0}>
              <SideNav
                {...props}
                sideNavs={formattedSideNavs}
                activeNavItemData={activeNavItemData}
                onMobileSideNavClose={onMobileSideNavClose}
                setShowSideNav={setShowSideNav}
                className='mobile'
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>}
      </Box>

      <Box display={{ base: 'none', lg: 'block' }}>
        <SideNav
          {...props}
          sideNavs={formattedSideNavs}
          activeNavItemData={activeNavItemData}
          setShowSideNav={setShowSideNav}
        />
      </Box>
    </Box>
  );
});
